import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"
import Help from "@components/help"

import { useTranslation } from 'react-i18next';


const HelpPage = () => {
  const { t } = useTranslation();
  
  return (
    <Layout>
      <SEO title={ t('seo.need_help' )} />
      <Help />
    </Layout>
  )
}

export default HelpPage
